module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"quality":75,"withWebp":true,"imageClass":"block mx-auto my-6"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Antonio Gázquez","short_name":"Antonio Gázquez","start_url":"/","background_color":"#f8fafc","theme_color":"#3d4852","icon":"src/img/icon.jpg"},
    }]
